import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import Container from '../components/Layout/SharedStyles/Container';
import ContactForm from '../components/Forms/ContactForm';
import PageHeading from '../components/Layout/PageHeading/PageHeading';
import ContactIntro from '../components/Contact/ContactIntro';

const ContactPages = ({
  data: {
    datoCmsContactPage: { title, id, metaTags, heading, subHeading, text, phone, email, image, video },
    homeCrumb,
    translations
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];

  return (
    <PageWrapper
      breadcrumbs={breadcrumbs}
      pageData={pageContext}
      metaTags={metaTags}
    >
      <PageHeading heading={heading} subHeading={subHeading} />
      <Container width='small'>
        <ContactIntro text={text} phone={phone} email={email} image={image} video={video}/>
      </Container>
      <Container width="small" pt={2} pb={2}>
        <ContactForm translations={translations} locale={pageContext.locale} />
      </Container>
    </PageWrapper>
  );
};

export default ContactPages;

export const query = graphql`
  query ContactPageQuery($locale: String!) {
    datoCmsContactPage(locale: { eq: $locale }) {
      locale
      title
      heading
      subHeading
      text
      phone
      email
      video {
        providerUid
        provider
        url
      }
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1100", h: "600", fit: "crop", q: 60 }
        )
      }
      metaTags {
        title
        description
        image {
          url
        }
      }
      id: originalId
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
    translations: datoCmsTranslation(locale: { eq: $locale }) {
      id: originalId
      locale
      formErrorIsRequired
      formErrorSomethingWentWrong
      formErrorWeAreSorry
      formFieldEmail
      formFieldMessage
      formFieldName
      formFieldPhone
      formFieldPostcode
      formFieldSubmit
      formSuccessBeInTouch
      formSuccessThanks
      formTitle
      formYour
      formEnterYour
      formAddYour
      formMustBeValid
      formCheckboxOne
    }
  }
`;
