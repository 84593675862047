import React from 'react';
import style from './contact-intro.mod.scss';
import Video from '../Video/Video';

const ContactIntro = ({ text, phone, email, video, image }) => {
  return (
    <div className={style.intro}>
      <p>{text}</p>
      <div className={style.intro__items}>
        {phone && <p>P: {phone}</p>}
        {email && <p>E: {email}</p>}
      </div>
      {video && image && (
        <div className={style.intro__video}>
          <Video video={video} placeholderImage={image} />
        </div>
      )}
    </div>
  );
};

export default ContactIntro;
