import React from 'react';
import style from './page-heading.mod.scss';
import Container from '../SharedStyles/Container';

const PageHeading = ({ heading, subHeading }) => {
  return (
    <Container pt={2} pb={2} width={'small'}>
      <div className={style.heading}>
        <p>{subHeading}</p>
        <h1>{heading}</h1>
      </div>
    </Container>
  );
};

export default PageHeading;
