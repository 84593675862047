import React from 'react';
import cn from 'classnames';
import { useFormContext, Controller } from 'react-hook-form';
import style from './formCheckbox.mod.scss';

const FormCheckbox = ({ label, name, message, defaultValue }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const error = errors.hasOwnProperty(name) ? errors[name] : null;

    const classes = cn(style.formCheckbox__input, {
        [style[`formCheckbox__input-error`]]: !!error === true,
    });

    return (
        <div className={style.formCheckbox}>
            <div className={style.formCheckbox__input}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, onBlur, name, ref } }) => (
                        <div className={style.formCheckbox__content}>
                            <div>
                                <input
                                    className={classes}
                                    id={label}
                                    inputRef={ref}
                                    type="checkbox"
                                    name={name}
                                    defaultValue={defaultValue}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            </div>
                            <div>

                            </div>
                        </div>
                    )}
                />
            </div>
            <div className={style.formCheckbox__label}>
                {label && (
                    <label  htmlFor={label}>
                        {label}
                    </label>

                )}
                {!!error && (
                    <div className={style.formCheckbox__error}>
                        {error?.message}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormCheckbox;
