import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Container from '../Layout/SharedStyles/Container';
import Button from '../Button/Button';
import FormWrapper from './FormElements/FormWrapper/FormWrapper';
import FormRow from './FormElements/FormRow/FormRow';
import FormTextField from './FormElements/FormTextField/FormTextField';
import FormTextEmail from './FormElements/FormTextEmail/FormTextEmail';
import FormTextNumber from './FormElements/FormTextNumber/FormTextNumber';
import FormTextArea from './FormElements/FormTextArea/FormTextArea';
import style from './contact.mod.scss';
import FormCheckbox from './FormElements/FormCheckbox/FormCheckbox';

const ContactForm = ({ locale, translations }) => {
  const fullNameText = translations.formFieldName || 'Name';
  const emailText = translations.formFieldEmail || 'Email';
  const phoneText = translations.formFieldPhone || 'Phone Number';
  const postcodeText = translations.formFieldPostcode || 'Postcode';
  const messageText = translations.formFieldMessage || 'Message';
  const isRequiredText = translations.formErrorIsRequired || 'is required';
  const somethingWentWrongText =
    translations.formErrorSomethingWentWrong ||
    'Something went wrong, please try again later';
  const weAreSorryText = translations.formErrorWeAreSorry || 'We are sorry';
  const thanksText = translations.formSuccessThanks || 'Thank you';
  const beInTouchText =
    translations.formSuccessBeInTouch || 'We will be in touch shortly';
  const formTitleText = translations.formTitle || 'Contact us';
  const submitText = translations.formFieldSubmit || 'Submit Form';
  const yourText = translations.formYour || 'Your';
  const enterYourText = translations.formEnterYour || 'Enter Your';
  const addYourText = translations.formAddYour || 'Add Your';
  const mustBeValidText = translations.formMustBeValid || 'must be valid';
  const checkboxOneText = translations.formCheckboxOne || null;
  //variable use state
  const [contactFormName, setContactFormName] = useState("contact-form");

  useEffect(() => {
    if (locale === 'fr-FR') {
      setContactFormName("contact-form-fr");
    }
  }, [locale]);

  const schema = yup
    .object({
      fullName: yup.string().required(`${fullNameText} ${isRequiredText}`),
      email: yup
        .string()
        .email(`${emailText} ${mustBeValidText}`)
        .required(`${emailText} ${isRequiredText}`),
      phoneNumber: yup.string().required(`${phoneText} ${isRequiredText}`),
      checkboxOne: yup
      .string()
      .when('checkboxOneText', {
        is: (value) => checkboxOneText !== null,
        then: yup.string().required(`${isRequiredText}`),
        otherwise: yup.string(),
      }),
      postcode: yup.string().required(`${postcodeText} ${isRequiredText}`),
      message: yup
      .string()
      .when('messageText', {
        is: (value) => locale !== 'fr-FR',
        then: yup.string().required(`${messageText} ${isRequiredText}`),
        otherwise: yup.string(),
      }),      
    })
    .required();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  console.log("LOCALE IS: ", locale)

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    handleFormSubmit(data);
  };
 
  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  }

  const handleFormSubmit = (data) => {
    // event.preventDefault();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': locale === 'fr-FR' ? 'contact-form-fr' : 'contact-form',
        subject: getSubject(),
        ...data,
      }),
    })
      .then((res) => (res.status === 200 ? setSuccess(true) : setError(true)))
      .catch((error) => alert(error));
  };



  const getSubject = () => {
    if (locale === 'en-NZ') {
      return 'Contact Us Form from Vortex NZ';
    }
    if (locale === 'en-GB') {
      return 'Contact Us Form from Vortex GB';
    }
    if (locale === 'en-EU') {
      return 'Contact Us Form from Vortex EU';
    } else return 'Contact Us Form from Vortex AU';
  };

  return (
    <>
      {success || error ? (
        <Container width="small" pt={2} pb={2} gutters={false}>
          {success && (
            <>
              <h4>{thanksText}</h4>
              <p>{beInTouchText}</p>
            </>
          )}
          {error && (
            <>
              <h4>{weAreSorryText}</h4>
              <p>{somethingWentWrongText}</p>
            </>
          )}
        </Container>
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            name={contactFormName}
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            id={contactFormName}
          >
            <input
              type="hidden"
              id="subject"
              name="subject"
              value={getSubject}
            />
            <input type="hidden" name="form-name" value={contactFormName} />
            <Container width="small" pt={2} pb={2} gutters={false}>
              <div className={style.contact}>
                <div className={style.contact__body}>
                  <h3 className={style.contact__subHeading}>{formTitleText}</h3>

                  <FormWrapper>
                    <FormRow columns={1}>
                      <FormTextField
                        label={`${yourText} ${fullNameText} *`}
                        placeholder={`${enterYourText} ${fullNameText}`}
                        name="fullName"
                      />
                    </FormRow>
                    <FormRow columns={1}>
                      <FormTextEmail
                        label={`${yourText} ${emailText} *`}
                        placeholder={`${enterYourText} ${emailText}`}
                        name="email"
                      />
                    </FormRow>
                    <FormRow columns={1}>
                      <FormTextNumber
                        label={`${yourText} ${phoneText} *`}
                        placeholder="(##) ### ### or ### ### ####"
                        name="phoneNumber"
                      />
                    </FormRow>
                    <FormRow columns={1}>
                      <FormTextNumber
                        label={`${yourText} ${postcodeText} *`}
                        placeholder={`${enterYourText} ${postcodeText}`}
                        name="postcode"
                      />
                    </FormRow>
                    <FormRow columns={1}>
                      <FormTextArea
                        rows={3}
                        label={`${messageText} ${locale !== 'fr-FR' ? ' *' : ''}`}
                        placeholder={`${addYourText} ${messageText}`}
                        name="message"
                      />
                    </FormRow>
                    {checkboxOneText && (
                    <FormRow columns={1}> 
                      <FormCheckbox
                        rows={3}
                        label={`${checkboxOneText} *`}
                        message={`${checkboxOneText}`}
                        name="checkboxOne"
                      />
                    </FormRow>
                    )}
                    <Button type="submit" primary>
                      {submitText}
                    </Button>
                  </FormWrapper>
                </div>
              </div>
            </Container>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default ContactForm;
